<template>
  <div class="lottery">
    <div class="btns">
      <button @click="getRecord()" class="my_prize"></button>
      <button @click="popup.rule = true" class="rule"></button>
    </div>
    <div class="gameBox">
      <div class="bg1">
        <div class="info">
          <text-scroll v-if="noticeList.length!==0" :dataList="noticeList"></text-scroll>

          <!--<p class="row">-->
          <!--<span>xxx</span>-->
          <!--<span>Apple watch s6</span>-->
          <!--</p>-->
          <!--<p class="row">-->
          <!--<span>xxx</span>-->
          <!--<span>Apple watch s6</span>-->
          <!--</p>-->
        </div>
      </div>
      <div class="bg2" v-show="lampShow"></div>
      <div class="start" @click="move">
        <!--<p>（{{number_of_draws}}次）</p>-->
      </div>
      <ul>
        <li v-for="(item,i) in list" :key="i" :class="['item' + (i+1), {'active': index == i}]">
          <div class="img1">
            <img :src="item.awardImageUrl" alt="">
          </div>
          <!--<p>+{{item.number}}{{item.prize_name}}</p>-->
        </li>
      </ul>
    </div>
    <!--弹窗-->
    <div class="popup">
      <div v-if="popup.rule" class="spop-wrap">
        <div @click="popup.rule = false" class="spop-bg"></div>
        <div class="spop-main rule">
          <i @click="popup.rule = false" class="close"></i>
          <div class="spop-box">
            <div class="spop-child"><i></i>
              <div class="spop-content">
                <!--礼品规则-->
                <p>1、实物奖品中奖用户需提供真实有效信息，否则视为自动放弃领奖资格；</p>
                <p>2、奖励金奖品，中奖用户需提供正确手机号码，若提供号码有误，视为自动放弃；</p>
                <p>4、权益奖品，用户兑换后需保存兑换码，兑换码丢失可到中国移动手机俱乐部微信公众号回复“云听”重新领取，领取手机号码需和中奖手机号码保持一致；</p>
                <p>3、活动奖品（含奖励金）在活动结束后2周内发放。</p>
                <p>4、和包奖励金领取规则：</p>
                <p class="indent">
                  A、参与本活动获得的奖励金将下发至用户提交的手机号码对应的和包支付奖励金账号中，未开通和包支付账户的用户，系统会自动下发至用户手机号码对应的账户中，系统不会自动为用户开通和包支付账号，用户开通和包支付账号后，即可在账号中查到发放的奖励金。</p>
                <p class="indent">
                  B、本活动所得奖励金有效期为7天，用户可主动转赠，每笔奖励金限主动转赠一次，主动转赠的奖励金有效期不变。用户主动转赠的奖励金若未在有效期（7天）内被获赠用户转入银行卡，则该奖励金失效；</p>
                <p class="indent">C、奖励金规则详见和包奖励金页面，奖励金包页面路径：和包支付客户端-我的-卡片管理；</p>
                <p>5、云听VIP会员权益领取规则：</p>
                <p class="indent">A、权益信息：本次活动领取的是360天“云听客户端”VIP会员体验权益，价值268元，兑换截 止日期为2020年12月31日。</p>
                <p class="indent">B、权益兑换方式：下载“云听客户端”后，在“我的-VIP会员-兑换码”中输入激活码即可完成兑换，当日生效，有效期360天（已有VIP会员将自动延期）</p>
                <p class="indent"> C、活动期间，单用户号码仅限领取一次。</p>

                <p>如遇到问题，可在“中国移动手机俱乐部”微信公众号进行留言，客服会进行处理。</p>
              </div>
              <!--<span class="spop-close"><i class="spop-iconfont spop-icon-close"></i></span>-->
              <!--<button class="spop-button-confirm">确认</button>-->
            </div>
          </div>
        </div>
      </div>
      <div v-if="popup.popupPrize" class="spop-wrap">
        <div @click="popup.popupPrize = false" class="spop-bg"></div>
        <div class="spop-main my-prize">
          <i @click="popup.popupPrize = false" class="close"></i>
          <div class="spop-box">
            <div class="spop-child"><i></i>
              <div class="spop-content">
                <ul class="list">
                  <li v-for="i in awardInfo">
                    <span>{{ i.awardName }}</span><span>1</span><span>{{ i.luckTime.substring(0, 10) }}</span><span
                    @click="operation(i)">操作</span>
                  </li>
                </ul>
                <!--<div class="personal-info" v-if="index==0" v-for="(i,index) in awardInfo">-->
                <!--<h3 @click="popup.popupPrize=false;popup.infoUpdate=true;luckRecordId=i.luckRecordId;receiver={}"-->
                <!--class="title"></h3>-->
                <!--<div class="detail">-->
                <!--<div>您的姓名：<span>{{i.prizeReceiverName}}</span></div>-->
                <!--<div>您的手机：<span>{{i.prizeReceiverMobile}}</span></div>-->
                <!--<div>您的地址：<span>{{i.prizeReceiverAddress}}</span></div>-->
                <!--</div>-->
                <!--</div>-->

              </div>
              <!--<span class="spop-close"><i class="spop-iconfont spop-icon-close"></i></span>-->
              <!--<button class="spop-button-confirm">确认</button>-->
            </div>
          </div>
        </div>
      </div>
      <div v-if="popup.infoUpdate" class="spop-wrap">
        <div @click="popup.infoUpdate = false" class="spop-bg"></div>
        <div class="spop-main infoUpdate">
          <i @click="popup.infoUpdate = false" class="close"></i>
          <div class="spop-box">
            <div class="spop-child"><i></i>
              <div class="spop-content">
                <ul class="form">
                  <li v-if="luckRecord.awardType == 0"><input v-model="receiver.name" placeholder="填写姓名" type="text">
                  </li>
                  <li><input v-model="receiver.mobile" placeholder="填写手机号码" maxlength="11" type="tel"></li>
                  <li v-if="luckRecord.awardType == 0"><input v-model="receiver.address" placeholder="填写居住地址"
                                                              type="text"></li>
                </ul>
              </div>
              <span class="spop-close"><i class="spop-iconfont spop-icon-close"></i></span>
              <button @click="receiverUpdate(luckRecord)" class="spop-button-confirm"></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="popup.guanzhu" class="spop-wrap">
        <div class="spop-bg"></div>
        <div class="spop-main guanzhu">
          <!--<i @click="popup.guanzhu = false" class="close"></i>-->
          <div class="spop-box">
            <div class="spop-child"><i></i>
              <div class="spop-content">
                <img src="../assets/images/popup/guanzhu.png" alt="">
              </div>
              <!--<span class="spop-close"><i class="spop-iconfont spop-icon-close"></i></span>-->
              <!--<button class="spop-button-confirm"></button>-->
            </div>
          </div>
        </div>
      </div>
      <div v-if="popup.qrcode" class="spop-wrap">
        <div class="spop-bg"></div>
        <div class="spop-main qrcode">
          <!--<i @click="popup.guanzhu = false" class="close"></i>-->
          <div class="spop-box">
            <div class="spop-child"><i></i>
              <div class="spop-content">
                <img src="../assets/images/popup/code.png" alt="">
              </div>
              <!--<span class="spop-close"><i class="spop-iconfont spop-icon-close"></i></span>-->
              <!--<button class="spop-button-confirm"></button>-->
            </div>
          </div>
        </div>
      </div>
      <div v-if="popup.again" class="spop-wrap">
        <div @click="popup.again = false" class="spop-bg"></div>
        <div class="spop-main again">
          <i @click="popup.again = false" class="close"></i>
          <div class="spop-box">
            <div class="spop-child"><i></i>
              <div class="spop-content">
              </div>
              <span class="spop-close"><i class="spop-iconfont spop-icon-close"></i></span>
              <button @click="popup.again=false;otherPopup()" class="spop-button-confirm"></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="popup.nowin" class="spop-wrap">
        <div @click="popup.nowin = false" class="spop-bg"></div>
        <div class="spop-main nowin">
          <i @click="popup.nowin = false" class="close"></i>
          <div class="spop-box">
            <div class="spop-child"><i></i>
              <div class="spop-content">
              </div>
              <span class="spop-close"><i class="spop-iconfont spop-icon-close"></i></span>
              <button @click="popup.nowin = false;otherPopup()" class="spop-button-confirm"></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="popup.popupTimes" class="spop-wrap">
        <div @click="popup.popupTimes = false" class="spop-bg"></div>
        <div class="spop-main popupTimes">
          <i @click="popup.popupTimes = false" class="close"></i>
          <div class="spop-box">
            <div class="spop-child"><i></i>
              <div class="spop-content">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--实物奖品-->
      <div v-if="popup.entity" class="spop-wrap">
        <div @click="popup.entity = false" class="spop-bg"></div>
        <div class="spop-main entity">
          <i @click="popup.entity = false" class="close"></i>
          <div class="spop-box">
            <div class="spop-child"><i></i>
              <div class="spop-content">
                <div class="prize-name">{{ prize_data.luckTip }}</div>
                <ul class="form">
                  <li><input v-model="receiver.name" placeholder="填写姓名" type="text"></li>
                  <li><input v-model="receiver.mobile" placeholder="填写手机号码" maxlength="11" type="tel"></li>
                  <li><input v-model="receiver.address" placeholder="填写居住地址" type="text"></li>
                </ul>
              </div>
              <span class="spop-close"><i class="spop-iconfont spop-icon-close"></i></span>
              <button
                @click="receiverUpdate(prize_data, 'entity');"
                class="spop-button-confirm"></button>
            </div>
          </div>
        </div>
      </div>
      <!--虚拟奖品-->
      <div v-if="popup.virtual" class="spop-wrap">
        <div @click="popup.virtual = false" class="spop-bg"></div>
        <div class="spop-main virtual">
          <i @click="popup.virtual = false" class="close"></i>
          <div class="spop-box">
            <div class="spop-child"><i></i>
              <div class="spop-content">
                <div class="prize-name">{{ prize_data.luckTip }}</div>
                <ul class="form">
                  <li><input v-model="receiver.mobile" placeholder="填写手机号码" maxlength="11" type="tel"></li>
                </ul>
              </div>
              <span class="spop-close"><i class="spop-iconfont spop-icon-close"></i></span>
              <button
                @click="receiverUpdate(prize_data,'virtual');"
                class="spop-button-confirm"></button>
            </div>
          </div>
        </div>
      </div>
      <!--会员-->
      <div v-if="popup.vip" class="spop-wrap">
        <div @click="popup.vip = false" class="spop-bg"></div>
        <div class="spop-main virtual">
          <i @click="popup.vip = false" class="close"></i>
          <div class="spop-box">
            <div class="spop-child"><i></i>
              <div class="spop-content">
                <div class="prize-name">{{ prize_data.luckTip }}</div>
              </div>
              <span class="spop-close"><i class="spop-iconfont spop-icon-close"></i></span>
              <button @click="linkto(prize_data.otherTip)" class="spop-button-confirm"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vant/lib/dialog/style'
// @ is an alias to /src
// import { Dialog } from 'vant';
import { Dialog, Toast } from 'vant'
import wx from 'weixin-js-sdk'
import axios from 'axios'
import { GetQueryString, WXAuthorization } from '../assets/js/common'
import textScroll from '../components/text-scroll'

export default {
  name: 'lottery',
  data () {
    return {
      luckRecord: '',
      noticeList: [], // 中奖记录
      popup: {
        popupTimes: false,
        infoUpdate: false,
        popupPrize: false,
        again: false,
        rule: false,
        guanzhu: false,
        qrcode: false,
        nowin: false,
        entity: false,
        virtual: false,
        vip: false
      },
      awardInfo: [],
      list: [],//奖品列表
      index: -1, // 当前转动到哪个位置，第一次起点位置0,对应页面item1位置
      count: 8, // 总共有多少个位置
      times: 0, // 转动跑格子次数,
      cycle: 60, // 转动基本次数：即至少需要转动多少次再进入抽奖环节
      speed: 200, // 初始转动速度
      lampShow: false,//开始抽奖，灯光闪烁
      timer: 0, // 转动定时器
      lamp: 0, // 灯光定时器
      prize: 0, // 中奖位置，接口返回
      number_of_draws: 0,//限制每天抽奖次数，接口返回
      canOtherCount: 0,//>0显示二维码弹窗
      prize_data: {//中奖信息
        id: Number,//奖品ID
        name: '',//奖品名称
        number: Number,//奖品数量
        image: '',//奖品图片
        type: Number,// 奖品类型
      },
      // his
      receiver: {
        mobile: '',
        name: '',
        address: ''
      },
      awardVirtual: {},
      awardSooth: {},
      awardInfo: {},
      status: false,
      popupLl: false,
      popupHuawei: false,
      popupVirtual: false,
      popupSooth: false,
      popupShare: false,
      popupShare1: false,
      popupJoin: false,
      popupRecords: false,
      popupRule: false,
      loginFlag: false
    }
  },
  components: {
    textScroll
  },
  created: function () {
    let uid = GetQueryString('uid')
    if (uid) {
      WXAuthorization((data) => {
          console.log('已关注')
          // 已关注
          this.loginFlag = data.loginFlag
          this.getConfig()
          this.getTimes()
          this.recordList()
          this.getDetail(data.appid)
        },
        (data) => {
          console.log('未关注')
          this.getDetail(data.appid)
          this.getConfig()
          this.recordList()
          // 未关注
          // this.popup.guanzhu = true
          // this.loginFlag = data.loginFlag
          // this.getConfig()
          // // this.getTimes()
          // this.recordList()
          // this.getDetail()
          // this.wxShare(data.appid)
          // Toast({
          //   message: '关注公众号'
          // })
          // Dialog({message: '提示'});
        })
    } else {
      this.popup.guanzhu = true
      this.getConfig()
      this.recordList()
    }
  },
  mounted () {
    // Toast('test')

    // this.test()
    //获取奖品列表
    // this.axios.post('/api/luckdraw/prizeList').then(res => {
    //   if (res.status == 1) {
    //     this.list = res.data.list; // 奖品列表数据
    //     this.number_of_draws = res.data.number_of_draws; // 该用户剩余抽奖次数
    //   }
    // })
  },
  methods: {
    linkto (url) {
      window.location.href = url
    },
    operation (i) {
      this.popup.popupPrize = false
      if (i.awardType == 15) {
        window.location.href = i.otherTip
      } else {
        this.popup.infoUpdate = true
      }
      this.luckRecord = i
      this.receiver = {
        mobile: i.prizeReceiverMobile,
        name: i.prizeReceiverName,
        address: i.prizeReceiverAddress
      }
    },
    otherPopup () {
      if (this.canOtherCount > 0) {
        this.popup.qrcode = true
        this.other()
      }
    },
    //手机号正则验证
    checkMobile (a, b) {
      var mPattern = /^1[0123456789]\d{9}$/ //http://caibaojian.com/regexp-example.html
      //输出 true
      console.log(mPattern.test(a))
      if (mPattern.test(a)) {
        return 1
      } else {
        Toast(b)
        return 0
      }
    },
    getRecord () {
      this.myRecord()
      this.popup.popupPrize = true
    },
    ltInit () {
      if (localStorage.getItem('timeData')) {
        let old_time = localStorage.getItem('timeData')
        let now_time = this.get_endtime()
        this.compare_res = this.compare_time(old_time)
        if (this.compare_res == 1) {//"当日"
          console.log('当日')
        } else {
          localStorage.setItem('number_of_draws', this.number_of_draws)
          let endtime = this.get_endtime()
          localStorage.setItem('timeData', endtime)
        }
      } else {
        let endtime = this.get_endtime()
        localStorage.setItem('timeData', endtime)
        localStorage.setItem('number_of_draws', this.number_of_draws)
      }
    },
    // 比较时间是否为当天
    compare_time (time) {
      var today_end = this.get_endtime()
      var time_old = time
      if (today_end == time_old) {
        return 1
      } else {
        return 0
      }
    },
    // 获取当日23：59：59时间
    get_endtime () {
      var time_end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
      var time_format = this.format_date(time_end)
      return time_format
    },
    // 获取当前时间的  年月日时分秒  的时间格式化 20191220100246
    format_date (now) {
      var year = now.getFullYear() //年
      var month = now.getMonth() + 1 //月
      var day = now.getDate() //日
      var hh = now.getHours() //时
      var mm = now.getMinutes() //分
      var ss = now.getSeconds() //秒

      var clock = year + ''
      if (month < 10) {
        clock += '0'
      }
      clock += month + ''

      if (day < 10) {
        clock += '0'
      }
      clock += day + ''

      if (hh < 10) {
        clock += '0'
      }
      clock += hh + ''

      if (mm < 10) {
        clock += '0'
      }
      clock += mm

      if (ss < 10) {
        clock += '0'
      }
      clock += ss

      return clock
    },
    wxShare: function (appId, data) {
      console.log(appId, data)
      $.ajax({
        type: 'post',
        async: false,
        url: '/act/wechat/core/js/sign',
        dataType: 'json',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Access-Control-Allow-Origin', 'https://mcn.i139.cn')
        },
        data: {
          url: window.location.href,
          platformId: GetQueryString('platformId')
        },
        success: function (xhr) {
          console.log('sign', xhr)
          if (xhr.errcode == '0') {
            wx.config({
              debug: false,
              appId: appId, // 必填，公众号的唯一标识
              timestamp: xhr.timestamp, // 必填，生成签名的时间戳
              nonceStr: xhr.noncestr, // 必填，生成签名的随机串
              signature: xhr.signature, // 必填，签名
              jsApiList: [
                'checkJsApi',
                'onMenuShareTimeline',
                'onMenuShareAppMessage',
                'onMenuShareQQ',
                'onMenuShareWeibo',
                'onMenuShareQZone',
              ]
            })
          }
        }
      })

      wx.ready(function () {
        wx.onMenuShareAppMessage({
          title: data.shareTitle,
          desc: data.shareDesc,
          link: data.shareLink,
          imgUrl: data.shareImage,
          success: function () {
            // 用户确认分享后执行的回调函数 好友
            xhr({
              url: '/act/act/addtimes/task/wx/friends',
              data: {
                actObjId: GetQueryString('actId'),
                platformId: GetQueryString('platformId')
              },
              success: function (res) {
                if (res.retCode == '000000') {
                  console.warn(res)

                } else {
                  Toast(res.retMsg)
                }
              }
            })

          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }
        })
        wx.onMenuShareTimeline({
          title: data.shareTitle,
          desc: data.shareDesc,
          link: data.shareLink,
          imgUrl: data.shareImage,
          success: function () {
            // 用户确认分享后执行的回调函数 朋友圈
            xhr({
              url: '/act/act/addtimes/task/wx/moments',
              data: {
                actObjId: GetQueryString('actId'),
                platformId: GetQueryString('platformId')
              },
              success: function (res) {
                if (res.retCode == '000000') {
                  console.warn(res)

                } else {
                  Toast(res.retMsg)
                }
              }
            })
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }
        })
        wx.onMenuShareQQ({
          title: data.shareTitle,
          desc: data.shareDesc,
          link: data.shareLink,
          imgUrl: data.shareImage,
        })
        wx.onMenuShareWeibo({
          title: data.shareTitle,
          desc: data.shareDesc,
          link: data.shareLink,
          imgUrl: data.shareImage,
        })
        wx.onMenuShareQZone({
          title: data.shareTitle,
          desc: data.shareDesc,
          link: data.shareLink,
          imgUrl: data.shareImage,
        })
      })
    },
    // 分享api
    mOnly: function (m) {
      this.receiver.mobile = m.replace(/[^\d]/g, '')
    },
    async receiverUpdate (id, name) {
      console.log(id)
      if (!this.checkMobile(this.receiver.mobile, '请输入正确的手机号')) return
      var that = this
      var params = {
        luckRecordId: id.luckRecordId,
        mobile: that.receiver.mobile,
        platformId: GetQueryString('platformId')
      }
      // var awardType = this.prize_data.awardType

      var awardType = id.awardType
      // this.awardInfo.forEach(i => {
      //   if (i.awardType == 20) {
      //     awardType = i.awardType
      //   }
      // })
      console.log(awardType)
      if (awardType == 0) {
        if (!that.receiver.name) {
          Toast('请输入姓名！')
          return
        } else if (!that.receiver.address) {
          Toast('请输入详细地址！')
          return
        }
        params.name = that.receiver.name
        params.address = that.receiver.address

      }
      const { data } = await axios.get('/act/lottery/luck/receiver/update', {
        params
      })
      if (data.retCode == '000000') {
        if (name) {
          this.popup[name] = false
          this.otherPopup()
        } else {
          this.popup.infoUpdate = false
        }
        Toast(data.retMsg)
      } else {
        Toast(data.retMsg)
      }
      console.warn(data)
      // xhr({
      //   url: api.receiver,
      //   data: params,
      //   success: function (res) {
      //     if (res.retCode == '000000') {
      //       that.popupLl = false
      //       that.popupHuawei = false
      //       that.popupVirtual = false
      //       that.popupSooth = false
      //       Toast(res.retMsg)
      //     } else {
      //       Toast(res.retMsg)
      //     }
      //   }
      // })

    },
    checkBtn: function () {
      this.record()
      //     Toast('只差那么一点点<br />继续加油哦～')
      // Toast('您今天的抽奖机会已用完<br />请明天再来～')

    },
    async getDetail (appid) {
      const { data } = await axios.get(`/act/lottery/detail?actId=${GetQueryString('actId')}`)
      console.log(data)
      this.wxShare(appid, data.data)

    },
    async getConfig () {
      const { data } = await axios.get(`/act/lottery/prize/config?aid=${GetQueryString('aid')}`)
      data.data.unshift({
        awardName: '谢谢参与',
        prizeId: 0,
        totalAwardNum: 0,
        awardImageUrl: '/act/game/shuang11/static/img/thanks.png'
      })
      this.list = data.data // 奖品列表数据
    },
    async getTimes () {
      let params = {
        aid: GetQueryString('aid'),
        actId: GetQueryString('actId'),
        platformId: GetQueryString('platformId')
      }
      const { data } = await axios.get(`/act/lottery/times/my`, {
        params
      })
      if (data.retCode == '000000') {
        this.number_of_draws = data.data.joinTimesRemain
        this.canOtherCount = data.data.canOtherCount
      }
    },
    async other () {
      let params = {
        actObjId: GetQueryString('actId'),
        platformId: GetQueryString('platformId')
      }
      const { data } = await axios.get(`/act/act/addtimes/task/act/other`, {
        params
      })
      if (data.retCode == '000000') {
        this.getTimes()
      }
    },
    async myRecord () {
      let params = {
        aid: GetQueryString('aid'),
        pageNo: 0,
        pageSize: 3,
        platformId: GetQueryString('platformId')
      }
      const { data } = await axios.get(`/act/lottery/luck/record/my`, {
        params
      })
      console.log(data)
      if (data.retCode == '000000') {
        this.awardInfo = data.data
      }
    },
    async recordList () {
      let params = {
        actId: GetQueryString('actId'),
        aid: GetQueryString('aid'),
        pageNo: 0,
        pageSize: 100,
        awardType: 10,
        platformId: GetQueryString('platformId')
      }
      const { data } = await axios.get(`/act/lottery/luck/record/list`, {
        params
      })
      console.log(data)
      if (data.retCode == '000000' && data.data) {
        this.noticeList = data.data
        // this.awardInfo = data.data
      }
    },
    //点击开始抽奖
    async move () {
      if (this.number_of_draws == 0) {
        console.log(11)
        if (this.canOtherCount > 0) {
          console.log(123)
          this.popup.qrcode = true
          this.other()
        } else {

          console.log('canOtherCount', this.canOtherCount)
          this.popup.popupTimes = true
        }

        // if (this.canOtherCount > 0) {
        //   this.popup.nowin = true
        // } else {
        //   this.popup.popupTimes = true
        // }
        console.log('今日抽奖次数已用完,明天再来吧')
      } else if (this.times != 0) {
        Toast('正在抽奖中，请勿重复点击')
      } else {
        let params = {
          aid: GetQueryString('aid'),
          actId: GetQueryString('actId'),
          platformId: GetQueryString('platformId')
        }
        const { data } = await axios.get('/act/lottery/lt', {
          params
        })
        if (data.retCode == '000000') {
          // this.status = true
          // setTimeout(function () {
          //   this.status = false
          // }, 5000)
          if (data.data.code == -1) {

            console.log('canOtherCount', this.canOtherCount)

            // 抽奖次数用完
            // this.popupShare = true
            this.popup.popupTimes = true
            return
            // this.wxShare()
          } else if (data.data.code < 0) {
            Toast(data.data.unluckTip)
            return
          }

          let prizeIndex = 0
          if (!data.data.prizeId) {
          } else {
            prizeIndex = this.list.findIndex(function (item, index, arr) {
              return item.prizeId == data.data.prizeId
            })
          }
          this.prize = prizeIndex//中奖位置赋值，跑马灯最终停留位置，这里实际位置需要-1
          this.number_of_draws--//抽奖开始，次数-1
          this.speed = 200//每次抽奖速度初始化为200
          this.prize_data = data.data//已经拿到中奖信息，页面展示，等抽奖结束后，将弹窗弹出
          console.warn(prizeIndex)
          this.startRoll()//执行抽奖
          this.lamp = setInterval(() => {//灯光闪烁开启
            this.lampShow = !this.lampShow
          }, 500)
          // this.other()
          this.getTimes()
          // 100中奖
          // <0错误提示
          //0-100 未中奖
          // alert(JSON.stringify(res))

        } else {
          Toast(data.retMsg)
        }
      }
    },
    // 开始转动
    startRoll () {
      this.times += 1 // 转动次数
      this.oneRoll() // 转动过程调用的每一次转动方法，这里是第一次调用初始化
      this.usePrize()
    },

    // 每一次转动
    oneRoll () {
      let index = this.index // 当前转动到哪个位置
      const count = 8 // 总共有多少个位置
      index += 1
      if (index > count - 1) {
        index = 0
      }
      this.index = index
    },

    usePrize () {
      // 如果当前转动次数达到要求 && 目前转到的位置是中奖位置
      if (this.times > this.cycle + 10 && this.prize === this.index) {
        clearTimeout(this.timer) // 清除转动定时器
        clearTimeout(this.lamp) // 清除灯光定时器
        this.lampShow = false // 白色灯隐藏
        this.times = 0 // 转动跑格子次数初始化为0，可以开始下次抽奖
        setTimeout(() => {
          if (this.prize_data.code > 1) {
            if (this.canOtherCount > 0) {
              this.popup.nowin = true
            } else {
              this.popup.popupTimes = true
            }
            console.log('未中奖，谢谢参与')//未中奖提示弹出，
          }

          // 会员
          if (this.prize_data.awardType == 15) {
            this.popup.vip = true
          }
          // 虚拟奖品
          if (this.prize_data.awardType == 10) {
            this.popup.virtual = true
            // if (this.canOtherCount != 0) {
            //   this.popup.again = true
            // } else {
            //   this.popup.virtual = true
            // }
          }
          // 实物奖品
          if (this.prize_data.awardType == 0) {
            // if (this.prize_data.awardName == '华为路由器') {
            //   this.prize_data.class = 'TC7102'
            // }
            // if (this.prize_data.awardName.indexOf('s6') != -1) {
            //   this.prize_data.class = 's6'
            // }
            this.popup.entity = true
          }
        }, 500)
      } else {
        if (this.times < this.cycle - 20) {
          this.speed -= 4 // 加快转动速度
        } else {
          this.speed += 10 // 抽奖即将结束，放慢转动速度
        }
        this.timer = setTimeout(this.startRoll, this.speed)//开始转动
      }
    },
  }
}
</script>
<style lang="scss">
/*
整体布局采用定位实现
gameBox:父盒子，最外层背景图
bg1:灰色灯
bg2:点击开始按钮后，白色灯出现，同时要每个500s同bg1做切换隐藏显示
start：按钮样式
item1-8:通过定位方式将dom元素顺时针排列
active：点击开始开妞后，从1位置开始高亮，类似跑马灯
*/
.popup {
  .indent {
    text-indent: 2em;
  }

  .ruler-box {
    position: fixed;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }


  .ruler-box img {
    width: 88%;
    height: auto;
    position: relative;
    top: 50%;
    left: 44%;
    transform: translate(-50%, -50%);
  }
}

.lottery {
  width: 100%;
  height: 16.24rem;
  /*overflow: hidden;*/
  background: #ffdfac url("../assets/images/bg.png") no-repeat left top/ cover;
  padding-top: 3rem;
}

.btns {
  width: 5.6rem;
  margin: 0 auto 0.48rem;
  display: flex;
  justify-content: space-between;

  .my_prize {
    width: 2.27rem;
    height: 0.95rem;
    background: url("../assets/images/lottery/btn-my-prize.png") no-repeat left top / contain;
  }

  .rule {
    width: 2.27rem;
    height: 0.95rem;
    background: url("../assets/images/lottery/btn-prize-rule.png") no-repeat left top / contain;
  }
}

.gameBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.8rem;
  height: 5.8rem;
  margin: 0 auto;
  border-radius: 8px;
  /*background: url(../assets/images/抽奖页/转动光点-01.png) no-repeat left top;*/
  /*background-size: 326px 326px;*/
  position: relative;

  .bg1 {
    position: absolute;
    left: 4.5px;
    top: 4px;
    width: 5.61rem;
    height: 8.33rem;
    background: url(../assets/images/lottery/ltbg01.png) no-repeat center;
    background-size: 5.61rem 8.33rem;
  }

  .info {
    width: 3.92rem;
    height: .8rem;
    position: absolute;
    left: 1.2rem;
    margin: 0 auto;
    font-size: .22rem;
    color: #4f3610;
    z-index: 9;
    overflow: hidden;
    bottom: .1rem;

    .row {
      display: flex;
      justify-content: space-between;
    }

    span {
      display: inline-block;
      width: 50%;
      text-align: left;

      &:nth-child(2) {
        padding-left: 1em;
      }
    }
  }

  .bg2 {
    position: absolute;
    left: 4.5px;
    top: 4px;
    width: 5.61rem;
    height: 8.33rem;
    background: url(../assets/images/lottery/ltbg02.png) no-repeat center;
    background-size: 5.61rem 8.33rem;
  }

  .start {
    position: relative;
    padding-top: 70px;
    width: 1.45rem;
    height: 1.45rem;
    background: url(../assets/images/lottery/开始抽奖.png) no-repeat center;
    background-size: 1.45rem 1.45rem;

    p {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }

  ul {
    li {
      position: absolute;
      width: 1.45rem;
      height: 1.45rem;
      background: rgba(255, 255, 255, 1);
      border: 2px solid #503510;
      border-radius: 8px;

      .img1 {
        margin: 0.1rem auto 0.03rem;
        width: 1.33rem;
        height: 1.19rem;
        line-height: 0.8rem;

        img {
          width: 100%;
          height: auto;
        }
      }

      p {
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        color: rgba(153, 153, 153, 1);
      }
    }

    .item1 {
      left: 0.62rem;
      top: 0.62rem;
    }

    .item2 {
      left: 2.16rem;
      top: 0.62rem;
    }

    .item3 {
      left: 3.7rem;
      top: 0.62rem;
    }

    .item4 {
      left: 3.7rem;
      top: 2.16rem;
    }

    .item5 {
      left: 3.7rem;
      top: 3.7rem;
    }

    .item6 {
      left: 2.16rem;
      top: 3.7rem;
    }

    .item7 {
      left: 0.62rem;
      top: 3.7rem;
    }

    .item8 {
      left: 0.62rem;
      top: 2.16rem;
    }

    .active {
      background: #f6d251;
    }
  }
}

</style>
