var xhr = function () {
  var ajax = function () {
      return ('XMLHttpRequest' in window) ? function () {
        return new XMLHttpRequest();
      } : function () {
        return new ActiveXObject("Microsoft.XMLHTTP");
      }
    }(),
    formatData = function (fd) {
      //把data对象里的参数转成send()里需要的字符串
      var res = '';
      for (var f in fd) {
        res += f + '=' + fd[f] + '&';
      }
      return res.slice(0, -1);
    },
    AJAX = function (ops) {
      var root = this,
        req = ajax();
      root.url = ops.url;
      // root.url = '';
      root.type = ops.type || 'responseText';
      root.method = ops.method || 'POST'; //默认的请求方式
      root.async = ops.async || true; //默认异步执行
      root.data = ops.data || {};
      root.complete = ops.complete || function () {
      };
      root.success = ops.success || function () {
      };
      root.error = ops.error || function (s) {
        //alert(root.url + '->status:' + s + 'error!')
        //失败时报出异常
        //alert('前端请求失败');
      };
      root.abort = req.abort;
      root.setData = function (data) {
        for (var d in data) {
          root.data[d] = data[d];
        }
      }
      root.send = function () {
        var datastring = formatData(root.data),
          sendstring, get = false,
          async = root.async,
          complete = root.complete,
          method = root.method,
          type = root.type;
        if (method === 'GET') {
          root.url += '?' + datastring;
          get = true;
        }
        req.open(method, root.url, async);
        if (!get) {
          req.setRequestHeader("Content-type",
            "application/x-www-form-urlencoded");
          sendstring = datastring;
        }
        // 在send之前重置onreadystatechange方法,否则会出现新的同步请求会执行两次成功回调(chrome等在同步请求时也会执行onreadystatechange)
        req.onreadystatechange = async ? function () {
          // console.log('async true');
          if (req.readyState == 4) {
            complete();
            if (req.status == 200) {
              root.success(JSON.parse(req[type]));
            } else {
              root.error(req.status);
            }
          }
        } : null;
        req.send(sendstring);
        if (!async) {
          // console.log('async false');
          complete();
          root.success(JSON.parse(req[type]));
        }
      }
      root.url && root.send();
    };
  return function (ops) {
    return new AJAX(ops);
  }
}();

export function GetQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURI(r[2]);
  return '';
}

//判断是否为手机safari浏览器
var isWXClient = function () {
  var ua = navigator.userAgent.toLowerCase();
  var isWeixin = ua.indexOf('micromessenger') != -1;
  return isWeixin ? true : false
}
var Cookie = {
  get: function (k) {
    return ((new RegExp(["(?:; )?", k, "=([^;]*);?"].join(""))).test(document.cookie) && RegExp["$1"]) || "";
  },
  set: function (k, v, e, d) {
    document.cookie = [k, "=", v, e && e["toGMTString"] ? ';expires=' + e.toGMTString() : "", ";path=/;domain=", d || ""].join("");
  },
  del: function (k) {
    document.cookie = k + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
  }
}

function back() {
  history.go(-1)
}

var api = {
  detail: '/act/lottery/wx/detail',
  lt: '/act/lottery/wx/lt',
  my: '/act/lottery/wx/luck/record/my',
  config: '/act/lottery/wx/prize/config',
  record: '/act/lottery/wx/luck/record/my',
  friends: '/act/act/addtimes/task/wx/friends',
  moments: '/act/act/addtimes/task/wx/moments',
  receiver: '/act/lottery/wx/luck/receiver/update',
}

//获取URL参数
function getQueryValue(n) {
  var m = window.location.search.replace(/(^\?+)|(#\S+$)/g, "");
  m = m.match(new RegExp("(^|&)" + n + "=([^&]*)(&|$)"));
  return !m ? "" : decodeURIComponent(m[2]);
}

// 用户授权部分<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// 判断用户是否已授权登录
$.ajax_isLogin = function (path, platformId, callback) {
  $.ajax({
    type: "post",
    async: true,
    url: '/' + path + '/wechat/core/user/isLogin',
    dataType: 'json',
    data: {
      platformId: platformId
      // u: '1', //可空，是否查询用户信息（已关注用户才有用户信息，同时返回用户的关注状态subscribe）1为查询
      // m: '1'//可空，是否查询用户绑定的手机号 1为查询
    },
    success: function (xhr) {
      console.log(xhr)
      if (xhr.loginFlag) {
        localStorage.vip_user_information = JSON.stringify(xhr);
      }
      if (typeof callback == 'function') callback(xhr);
    },
    error: function (xhr) {
      Util.popWindow(xhr.status)
      console.log(xhr)
    }
  })
};

//用户确认授权链接信息
function isImpower(path, platformId, thisNetUrl) {
  $.ajax({
    type: "post",
    async: true,
    url: '/' + path + '/wechat/core/oauth/page',
    dataType: 'json',
    data: {
      platformId: platformId,
      redirectUrl: thisNetUrl, //必填，要进行网页授权的url地址全称
      scope: 'snsapi_base', //可空，为空默认为用户确认授权，微信公众号则弹出提示框提示用户是否授权登录（静默授权snsapi_base(openid)，手工确认授权：snsapi_userinfo）
      // state: ''//可空，如果需要传递参数到redirectUrl中，请使用此字段
    },
    success: function (xhr) {

      window.location.href = xhr.data;
    }
  })
}

//根据用户确认授权链接信息接口返回的code查询用户信息
function userInfo(path, platformId, code, callback) {
  $.ajax({
    type: "post",
    async: true,
    url: '/' + path + '/wechat/core/oauth/openid',
    dataType: 'json',
    data: {
      platformId: platformId, //可空，为空默认为服务号（）
      code: code, //必填，接口1拼接在redirectUrl中的code值
    },
    //如果接口1的scope参数填的静默授权snsapi_base，则只返回uid，用户信息（昵称，头像等）为空
    //如果接口1的scope参数填的手工确认授权：snsapi_userinfo，则只返回uid和用户信息。
    success: function (xhr) {
      if (xhr == '' || xhr.loginFlag.toString() === 'false') {
        Util.popWindow("获取用户授权失败，请刷新页面重试")
      }
      if (typeof callback == 'function') callback(xhr);
    }
  })
}

// 订阅号免登陆
function useIdGetBlindInfo(path, uid, platformId, callback) {
  $.ajax({
    type: "get",
    async: true,
    url: '/' + path + '/wechat/core/user/ulogin',
    dataType: 'json',
    data: {
      uid: uid,
      platformId: platformId
    },
    success: function (xhr) {
      if (typeof callback == 'function') callback(xhr);
    }
  })
}

// 根据uid查询用户是否关注
function userIsSubscribe(path, uid, platformId, callback) {
  $.ajax({
    type: "post",
    async: true,
    url: '/' + path + '/wechat/core/user/isSubscribe',
    dataType: 'json',
    data: {
      uid: uid,
      platformId: platformId,
    },
    success: function (xhr) {
      if (typeof callback == 'function') callback(xhr);
    }
  })
}

// 获取公众号二维码
function getWeiXinCode(path, platformId, callback) {
  $.ajax({
    type: "get",
    async: true,
    url: '/' + path + '/wechat/mem/mp/qrcode',
    dataType: 'json',
    data: {
      platformId: platformId,
    },
    success: function (xhr) {
      if (typeof callback == 'function') callback(xhr);
    }
  })
}


//>>>>>>>>>>>>>>>>>>>>>>>>绑定手机部分>>>>>>>>>>>>>>>>>>>>>>>

//验证码倒计时
var countdown = 60,
  getMobileNub_time = 0;

function settime(val) {
  if (countdown == 0) {
    val.removeAttribute("disabled");
    val.value = "重新获取";
    countdown = 60;
    return
  } else {
    val.setAttribute("disabled", true);
    val.value = countdown + " s";
    countdown--;
  }
  setTimeout(function () {
    settime(val)
  }, 1000)
}

// 订阅号用服务号授权<<<<<<<<<<<<<<<<<<<------------------------->>>>>>>>>>>>>>>>>>>>
//用户确认授权链接信息
function dyisImpower(fwhId, redirectUrl) {
  $.ajax({
    type: "post",
    async: true,
    url: '/act/wechat/core/oauth/union/page',
    dataType: 'json',
    data: {
      fwhId: fwhId, //服务号id
      redirectUrl: redirectUrl, //必填，要进行网页授权的url地址全称
    },
    success: function (xhr) {
      window.location.href = xhr.data;
    }
  })
}

//根据用户确认授权链接信息接口返回的code查询用户信息
function dyuserInfo(fwhId, myDyhId, code, callback, callback2) {
  $.ajax({
    type: "post",
    async: true,
    url: '/act/wechat/core/oauth/union/user',
    dataType: 'json',
    data: {
      fwhId: fwhId, //服务号id
      platformId: myDyhId, //订阅号id
      code: code
    },
    success: function (xhr) {
      if (xhr.subscribe == 0) {
        callback(xhr)
      } else {
        if (typeof callback2 == 'function') callback2(xhr);
      }

    }
  })
}

//限制整数输入
$(document).on('input', 'input[data-key="int"]', function () {
  var this_value = $.trim($(this).val());
  if (this_value == "") {
    $(this).val("");
    return;
  }
  this_value = Number(this_value).toString();
  this_value = this_value.replace(/[^\d]/g, '');
  if (this_value != $(this).val()) $(this).val(this_value);
});

//手机号正则验证
function checkMobile(a, b) {
  var mPattern = /^1[0123456789]\d{9}$/; //http://caibaojian.com/regexp-example.html
  //输出 true
  console.log(mPattern.test(a));
  if (mPattern.test(a)) {
    return 1;
  } else {
    Util.popWindow(b);
    return 0;
  }
}

//修复IOS输入法收起窗口不回弹的异常问题
$(document).on('blur', "input", function (e) {
  setTimeout(function () {
    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
  }, 50);
});
//弹窗气泡
var Util = {
  popWindow: function (msg) {
    $('html').append('<section class="popupR"><span class="popupRText">' + msg + '</span></section>');
    setTimeout(function () {
      $('html').find('.popupR').remove();
    }, 2000)
  }
};
$.getMyLocalStorage = function (name) {
  var loc = window.localStorage && (localStorage[name] || (typeof localStorage.setItem == "function" && localStorage.getItem(name)));
  if (loc) {
    try {
      loc = JSON.parse(loc);
      // var new_loc = JSON.parse(loc);
      // time = time || 24;
      // if(new_loc._t > new Date().getTime() - time * 3600000) {
      //     loc = new_loc;
      // } else {
      //     loc = false;
      // }
    } catch (e) {
      loc = false;
    }
  } else {
    loc = false;
  }
  return loc;
};


// 授权封装函数 nomalCallback 授权成功后逻辑 noSubscribeCallback 未关注逻辑
export function WXAuthorization(nomalCallback, noSubscribeCallback) {
  var thisNetUrl = location.href;
  // var platformId = 'gh_1f43c117f0a7';
  var platformId = getQueryValue('platformId'); // 测试环境
  var actId = getQueryValue('actId');
  var fwhId = getQueryValue('fwhId');
  if (getQueryValue('code')) {
  } else {
    localStorage.setItem("hrefcode", thisNetUrl);
  }

  $.ajax_isLogin('act', platformId, function (xhr) {
    if (xhr.loginFlag.toString() === 'true') {
      if (xhr.subscribe.toString() === '1') {
        //正常逻辑
        if (typeof nomalCallback == 'function') nomalCallback(xhr);
      } else {
        //   // 弹窗公众号二维码
        if (typeof noSubscribeCallback == 'function') noSubscribeCallback(xhr);
      }
    } else {
      if (getQueryValue('fwhId')) {
        //订阅号 用服务号授权微信授权
        if (getQueryValue('code')) {
          dyuserInfo(fwhId, platformId, getQueryValue('code'), function (xhr) {
            noSubscribeCallback(xhr)
          }, function (d) {
            window.location.href = '' + localStorage.getItem("hrefcode");
          });
        } else {
          dyisImpower(fwhId, thisNetUrl);
        }
      } else {
        // 免鉴权
        if (getQueryValue('uid') && getQueryValue('uid').length > 3) {
          useIdGetBlindInfo('act', getQueryValue('uid'), platformId, function (xhr) {
            if (xhr.loginFlag.toString() === 'true') {
              $.ajax_isLogin('act', platformId, function (xhr) {
                if (xhr.subscribe.toString() === '1') {
                  //正常逻辑
                  if (typeof nomalCallback == 'function') nomalCallback(xhr);
                } else {
                  // 弹窗公众号二维码
                  if (typeof noSubscribeCallback == 'function') noSubscribeCallback(xhr);
                }
              })
            } else {
              Util.popWindow("用户鉴权失败，请重试！");
            }
          })
        } else {
          //服务号微信授权
          if (getQueryValue('code')) {
            userInfo('act', platformId, getQueryValue('code'), function (d) {
              window.location.href = '' + localStorage.getItem("hrefcode");
            });
          } else {
            isImpower('act', platformId, thisNetUrl);
          }
        }
      }
    }
  });
}
